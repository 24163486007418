.title{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem
}
.title::after,
.title::before{
    content: '';
    width: 75px;
    height: 1px;
    background-color: #4189dd;
}
