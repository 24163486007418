.signup{
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  padding: 2rem 0rem;
  background-color: #fff;
}
.label {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
}

.input,
.select {
  border: 0;
  border-radius: 0;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  font-size: 1.25rem;
  width: 100%;
}

.input:focus,
.select:focus {
  outline: none;
  box-shadow: none;
}

.input::placeholder {
  color: #7e7e7e;
  font-size: 14px;
}
.btn{
  width: 100%;
  background-color: rgba(34, 111, 201, 1);
  padding: .5rem 2rem;
  border-radius: 1rem;
  border: 1px solid rgba(34, 111, 201, 1);
  color: #fff;
}
.title{
  font-size: 1.75rem;
  color: #061A32;
  font-weight: 600;
}
.close{
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid #061A32;
  cursor: pointer;
}
.close span{
  position: relative;
  top: 0;
  right: 0;
}
.toggle_switch{
  width: 320px;
  display: flex;
  background-color: rgba(65, 137, 221, 0.1);
  border-radius: 1rem;
}
.toggle_switch input ~ label{
  width: 100%;
  padding: 1rem 1.75rem;
  border-radius: 1rem;
  text-align: center;
  cursor: pointer;
}
.toggle_switch input[type="radio"]{
  appearance: none;
}
.toggle_switch input:checked ~ label{
  font-weight: 600;
  background-color: #4189DD;
  color: #fff;
}