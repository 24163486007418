.label {
    font-size: 16px;
    font-weight: 400;
    padding-top: 2rem;
}

.input,
.select {
    border: 0;
    border-radius: 0;
    padding-left: 0;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    width: 100%;
}

.input:focus,
.select:focus {
    outline: none;
    box-shadow: none;
}

.input::placeholder {
    color: #7e7e7e;
    font-size: 14px;
}

.button {
    margin-top: 1.5rem;
    font-size: 14px;
    padding: 0.5rem 1rem;
    width: 100%;
}


.contactuslabel {
    color: #061A32;
    font-weight: 400;
    font-size: 17px;
}

.contactusblue {
    color: #4189DD;
    font-weight: 400;
    font-size: 17px;
}

.contactusred {
    color: #ff0000;
    font-weight: 400;
    font-size: 17px;
}

.contactusnotlabel {
    color: #A1A4B1;
    font-weight: 400;
    font-size: 17px;
}

.crosslastname {
    position: absolute;
    right: 0;
    top: 60px;
    cursor: pointer;
}

.selectMenu {
    width: 100%;
    height: 30vh;
    box-shadow: 1px 1px 0px 0px #fff;
    z-index: 9;
    left: 0;
}

/* .card {
    padding: 0.5rem;
} */

.dropdownitem1 {
    border: 1px solid #eee;
    padding: 0.5rem;
    cursor: pointer;
}

.dropdownitemlast {
    padding: 0.5rem;
    cursor: pointer;
}

.errorMsg,
.contactusred {
    color: #ff0000;
    font-size: 16px;
}

.disablebtn {
    opacity: 0.5;
}

.enablebtn {
    opacity: 1;
}