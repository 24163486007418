.buttons {
    background-color: rgba(65, 137, 221, 0.1);
    width: max-content;
    border-radius: 10px;
    /* margin-top: 1rem; */
}

.button {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    text-align: center;
    border: none;
    color: #01435a;
    border-radius: 8px;
    /* width: 110px; */
    /* height: 35px; */
    font-size: 16px;
    border-radius: 0;
    background-color: transparent;
    padding: 0.5rem 2rem;
    border-radius: 10px;
}

.button:hover{
    background-color: #4189dd;
}

.button.active {
    background-color: #4189dd;
    color: #fff;
}