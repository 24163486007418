.contactUs {
    padding: 4rem 0rem;
}

.title {
    font-weight: 700;
    width: max-content;
    position: relative;
    padding-bottom: 0.5rem;
    margin-bottom: 1.5rem;
}

.title::after {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: #ddd;
}
