.header{
    background-color: #4189dd;
}
.header .nav_link{
    font-weight: 600;
    color: #fff;
    padding: 0;
}
.header .nav_btn{
    font-weight: 600;
    text-decoration: none;
    padding: 0.75rem 1.5rem;
    border-radius: 0.75rem;
    background-color: #fff;
    color: #4189dd;
    box-shadow: 0px 5px 20px rgba(0,0,0,0.1);
}