.btn {
    text-decoration: none;
    padding: 0.75rem 2.5rem;
    border-radius: 0.75rem;
    display: inline-flex;
    align-items: center;
    gap: .25rem;
}
.btn.btn_success{
    background-color: #10A667;
    color: #fff;
    border: 1px solid #10A667;
}
.btn.btn_danger{
    background-color: rgba(255, 217, 217, 0.5);
    color: #FC5858;
    border: 1px solid rgba(255, 0, 0, 0.2);
}
.disclaimer_text{
    color: rgba(16, 54, 98, 0.5);
}
.custom_select{
    width: auto;
    font-weight: 600;
    padding: .375rem 2rem 0rem .75rem;
    font-size: 1.5rem;
    background-size: 18px;
    border-bottom: 2px solid #103662;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    appearance: none;
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 strokeLinecap=%27round%27 strokeLinejoin=%27round%27 strokeWidth=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e');
}
.custom_select:focus{
    outline: none;
}