.toggle_switch_bg{
    position: relative;
    overflow: hidden;
    width: 320px;
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: rgba(65, 137, 221, 0.1);
    border-radius: 1rem;
    margin-left: auto;
    margin-right: auto;
}
.toggle_switch{
    width: 50%;
    border-radius: 1rem;
    padding: 0.75rem 1.5rem;
    text-align: center;
    position: relative;
    z-index: 2;
    color: #01435A;
    cursor: pointer;
}
.toggle_switch_active{
    cursor: auto;
    font-weight: 600;
    color: #fff;
    background-color: #4189DD;
}
.slider_bg{
    width: 204px;
    height: 415px;
    padding: 0.75rem 0.75rem 0.75rem 0.5rem;
    margin: auto;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}