.hero_banner{
    min-height: 85vh;
    background-color: #4189dd;
    padding: 50px 0px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.hero_banner::before{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    background-image: url('../../assets/map.png');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    opacity: 0.75;
}