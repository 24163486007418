.aboutus {
    background-color: #fff;
}

.text_primary {
    color: #4189DD;
}

.text_secondary {
    color: #103662;
}

.card {
    padding: 1rem;
    border-radius: 1rem;
    border: 1px solid rgba(65, 137, 221, 0.3);
}

.btn_primary {
    text-decoration: none;
    padding: 0.75rem 1.5rem;
    border-radius: 0.75rem;
    background-color: #4189DD;
    color: #fff;
    border: 1px solid #4189DD;
}
.main_title{
    text-align: center;
    color: #103662;
}
.main_title span{
    color: #4189DD;
}
