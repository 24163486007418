.text_primary{
  color: rgba(6, 26, 50, 0.6);
}
.close{
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid #061A32;
  cursor: pointer;
  z-index: 9;
}
.close span{
  position: relative;
  top: 0;
  right: 0;
}